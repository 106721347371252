.common {
  height: 100vh;
  width: 100vw;
  display: flex;
  z-index:-1;/*描画を一番下に*/
  justify-content: center;
  text-align:center;
  background-color: #80c342;
  color: rgb(75, 75, 75);
}

.title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 80px;
}

.detail {
  margin-top: 10px;
  font-size: 0.7rem;
}

.ddl_rosen {
margin-top: 10px;
}

.blk_chosen {
  margin-top: 80px;
}
.chosen-station {
  height: 35px;
  width: 260px;
	text-align: center;
  padding: 1rem 0;
  margin: auto;
  text-decoration: none;
  border: 2px solid rgb(75, 75, 75);;
  background-color: #FFF;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center; /* 水平方向の中央揃え */
  align-items: center; /* 垂直方向の中央揃え */
  text-align: center; /* テキストの中央揃え */
}

.btn_choice {
	display: block;
	text-align: center;
	text-decoration: none;
	width: 150px;
	margin: auto;
	margin-top: 10px;
  padding: 0.3rem 2rem;
	font-weight: bold;
	background-image: linear-gradient(to right, #27acd9 0%, #b4e12b 100%);
	border-radius: 100vh;
	color: #fff;
	border: 2px solid #fff;
	box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
	-webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, .2);
  transition: all 1s 1s ease;
}

.btn_choice:hover {
	background-image: linear-gradient(to left, #27acd9 0%, #b4e12b 100%);
}

.icon {
  width: 1.5rem;
  margin: 0 5px;
}